import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getKundeansvarlige, moveKunderInArray } from '../kunder/kundeHelper';
import VelgKundeListe from '../kunder/VelgKundeListe';
import api from '../shared/lib/api';
import { IKunde, IRadgiver, IVeileder } from '../shared/lib/types';
import TilbakeTil from '../kunder/Tilbake';
import Spinner from '../spinner/Spinner';
import FeilmeldingSide from '../feilside/FeilmeldingSide';

type TParams = {
    veilederId: string;
};

const VeilederStart = () => {
    const { veilederId } = useParams<TParams>();
    const [kundeliste, setKundeliste] = useState<Array<IKunde>>();
    const [kundeansvarlige, setKundeansvarlige] = useState<Array<IRadgiver>>();
    const [veileder, setVeileder] = useState<IVeileder>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    let navigate = useNavigate();

    useEffect(() => {
        (() => {
            Promise.all([
                api.getVeileder(veilederId, null).then((veileder) => {
                    setVeileder(veileder);
                }),
                api.getKunder().then((kunder) => {
                    const egetRegnskapsKunde = kunder.find((kunde) => kunde.egetForetak);
                    const filteredKunder = moveKunderInArray(egetRegnskapsKunde, kunder);

                    setKundeliste(filteredKunder);
                    setKundeansvarlige(getKundeansvarlige(filteredKunder));
                }),
            ])
                .catch((res) => setServerErrorCode(res.status))
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Veileder velg kunde';
        })();
    }, [veilederId]);

    function onKundeSelected(kunder: Array<string>): void {
        navigate(`/kunder/${kunder[0]}/veileder/${veilederId}/steg`);
    }

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/veiledere" />;
    }

    return (
        <div className="veileder">
            <div className="page-header page-header--dark page-header--sm">
                <div className="container">
                    <h1>{veileder && veileder.name}</h1>
                </div>
            </div>
            <TilbakeTil url={`/veiledere`} tekst="Tilbake til veilederoversikt" />
            <div className="page">
                <div className="container">
                    <h2>VELG KUNDE</h2>

                    <div className="row">
                        <div className="col-12">
                            <VelgKundeListe kundeliste={kundeliste} kundeansvarlige={kundeansvarlige} onKundeSelected={onKundeSelected} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VeilederStart;
